<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <!-- <debug-btn :item="responseData"></debug-btn> -->
          <template v-slot:heading>
            <h3>{{title}}</h3>
          </template>
          <hr class="mt-10 mb-10">
          <div class="ml-3">
            <h3>Silahkan Pilih 3 Kandidat ASN Teladan Menurut Anda:</h3>
            <label>(Anda hanya dapat memilih 3 kandidat di lingkungan kerja anda)</label>
          </div>
          <v-row class="ml-3">
            <v-col cols="9">
              <v-autocomplete
                :items="listPegawaiSKPD"
                v-model="modelListPegawaiSKPD"
                label="Pilih 3 Kandidat ASN Teladan"
                :item-text="getNipNama"
                multiple
                chips
                return-object
                @change="limitCandidate"
                :search-input.sync="searchString"
                :disabled="disabledTambahKandidat"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="mt-4">
              <!-- <v-btn color="primary" @click="addRow()" :disabled="disabled" :loading="loadingBtn.add">Tambah Anggota</v-btn> -->
              <v-btn color="primary" @click="addRow()" :disabled="disabledTambahKandidat" :loading="loadingBtn.add">Tambahkan Kandidat</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="loadingBtn.dataPegawai" class="mt-5">
            <v-col v-for="i in [1,2,3,4,5,6]" :key="i">
              <v-skeleton-loader
              class="mx-auto"
              min-width="300"
              type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <div class="ml-3 mt-10" v-if="anggota.length > 0">
            <h4 class="display-2 mb-1 teal--text text--darken-2" align="center" style="height:auto">
              <b>{{nama_pokja}}</b>
            </h4>
          </div>
            <v-row class="mt-10" v-if="anggota.length > 0">
              <v-col v-for="(peg, i) in anggota" :key="i" align="center">
                <base-material-card
                  class="v-card-profile"
                  :avatar="peg.foto"
                  style="width:270px; "
                >
                  <v-card-text class="text-center" height='fit-content'>
                    <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:auto">
                      {{peg.nama}}
                    </h4>
                    <h4 class="font-weight-light grey--text mb-2">
                      NIP. {{peg.nip}}
                    </h4>
                    <h4 class="font-weight-light green--text" style="font-size:12px; height:auto">
                      {{peg.jabatan}}
                    </h4>
                    <h4 class="display-1 mb-2 mt-4 teal--text text--darken-2" style="height:auto">
                      <b>Berikan penilaian anda:</b>
                    </h4>
                    <div height='fit-content'>
                      <v-rating
                        v-model="peg.rating"
                        background-color="orange lighten-3"
                        :color="peg.disabled_rating == true ? 'orange darken-4' : 'orange'"
                        size="30"
                        :hover=true
                        :readonly="peg.disabled_rating"
                        @input="storeRatingAndReviewer(peg, i)"
                      >
                      </v-rating>
                      <v-btn color="error" small class="mr-0" @click="hapusKandidat(peg,i)" :disabled="disabledUlas == false">
                        <span><v-icon>mdi-delete</v-icon> Hapus Kandidat</span>
                      </v-btn>
                    </div>
                  </v-card-text>
                </base-material-card>
              </v-col>
            </v-row>
            <v-row v-else justify="center">
              <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
                <v-alert
                color="blue-grey"
                dark
                dense
                icon="mdi-account-group"
                prominent
              >
                Tidak ada anggota pegawai
              </v-alert>
              </v-col>
            </v-row>
            <v-row class="mt-5 mb-5">
              <v-col align="center">
                <v-btn color="primary" @click="fixSimpanKandidat()" :disabled="countPegawai < 3"> <span><v-icon>mdi-lock</v-icon> Fix Simpan {{countPegawai}} Kandidat</span></v-btn>&ensp;
                <!-- <v-btn color="primary" :disabled="countPegawai < 3" :href=" baseURL + 'survey/ulas-kandidat'" :loading="loadingBtn.add">Ulas {{countPegawai}} Kandidat</v-btn> -->
                <v-btn color="primary" :disabled="disabledUlas" :href=" baseURL + 'survey/ulas-kandidat'" :loading="loadingBtn.add">Ulas {{countPegawai}} Kandidat</v-btn>
              </v-col>
            </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogSurveyReferensiASNCerdas">
      <SurveyReferensiDetail :dataPegawai="dataPegawai" @onClose="(x)=>{dialogSurveyReferensiASNCerdas=x}" @onSubmitted="getDataReviewerSurveyReferensiASN()"/>
    </v-dialog>

    <v-dialog v-model="dialogSW" persistent>
      <v-card>
        <v-card-title>
          <!-- <span class="headline">Apa Kelebihan Dan Kekurangan <b>{{namaPegawai}}</b> Sebagai Kandidat ASN Teladan?</span> -->
          <span class="headline">Apa Kelebihan <b>{{namaPegawai}}</b> Sebagai Kandidat ASN Teladan?</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formSW">
            <v-row class="ma-3">
              <v-col cols=12>
                <v-textarea v-model="formStrengthWeakness.strength" :rules="[rules.required]" label="Kelebihan Diri" outlined :auto-grow="false" :no-resize="true"></v-textarea>
              </v-col>
              <!-- <v-col cols=6>
                <v-textarea v-model="formStrengthWeakness.weakness" :rules="[rules.required]" label="Kekurangan Diri" outlined :auto-grow="false" :no-resize="true"></v-textarea>
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="dialogSW = false">tutup</v-btn>
          <v-btn id="btn-simpan-strength-weakness" color="success" @click="simpanSW()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
// import SistemKerjaService from '@/services/SistemKerjaService'
import SKPService from '@/services/SKPService'
import {defaultRules} from '@/utils/lib'
import _g from '../../global'
// import FormIsiEkspektasiPerilaku from './FormIsiEkspektasiPerilaku.vue'
import {local} from '@/store/local'
import { mapState } from 'vuex'
// import _ from 'lodash'
import axios from 'axios'
// import SurveyReferensiService from '@/services/SurveyReferensiService'
import SurveyReferensiDetail from './SurveyReferensiDetail'

export default {
  components:{
    SurveyReferensiDetail
  },

  data(){
    return{
      valid:true,
      title:'Pilih Kandidat ASN Teladan Di Lingkungan Pemerintah Kota Bandung',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      detailData:{},
      triwulan:1,
      hides:[],
      selectedItem:{},
      tabItems:['Triwulan I', 'Triwulan II', 'Triwulan III', 'Triwulan IV', 'Tahunan'],
      tableValues:{},
      loadingBtn:{
        add:false,
        del:false,
        upd:false,
        dataPegawai:false
      },
      ratingList:['kurang', 'kurang berhasil', 'cukup', 'berhasil', 'sangat berhasil'],
      rules:{
        ...defaultRules
      },
      dialogTambahAnggotaPokja: false,
      dataPegawaiBawahan: null,
      dataEkspektasiBawahan:{},
      // dataRespon:{},
      isUmpanBalik: false,
      loadingEl:-1,
      loadingElUmpanBalik:-1,
      dataPegawaiBaru: [],
      listPegawaiSKPD: [],
      modelListPegawaiSKPD: [],
      avatarImages:{},
      kode_skpd: null,
      listStrukturPokja: [],
      pegawai: [],
      id_pokja: null,
      anggota: [],
      nama_pokja: '',
      nip_ketua: '',
      disabled: true,
      fromDateMenu: false,
      endDateMenu: false,
      fromDateVal: null,
      endDateVal: null,
      showItem:false,
      formTambah:{},
      formEdit:{},
      date: new Date(),
      rating: 0,
      dataPegawai:{},
      dialogSurveyReferensiASNCerdas:false,
      enableUlas:false,
      countPegawai:0,
      disabledTambahKandidat: false,
      searchString: '',
      formStrengthWeakness: {},
      dialogSW: false,
      namaPegawai: '',
      allowUlas:false,
      disabledUlas:true
    }
  },
  created(){
    let kode_skpd = this.kode_skpd ? this.kode_skpd : local.getLocal("kolok")
    this.getDataPegawaiSKPD(kode_skpd)
    this.getDataReviewerSurveyReferensiASN()
    // this.year = new Date().getFullYear()-1
    // this.triwulan = _g.getTriwulan().toString()
    // var kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
    // var base_url = window.location.origin
    // // var role = this.current.role
    // // var username = this.current.username
    // if(kode_skpd != '4.03.01'){
    //   store.commit('snackbar/setSnack',{message:'Maaf, anda tidak bisa mengakses halaman Sistem Kerja.', color:'warning'})
    //   let confirmAction = confirm("Maaf, anda tidak bisa mengakses halaman Sistem Kerja.");
    //   if (confirmAction) {
    //     window.location.href = base_url + '/#/dashboard'
    //   } else {
    //     window.location.href = base_url + '/#/dashboard'
    //   }
    // }
    // // this.updateTable()
  },

  computed:{
    ...mapState({
      username: state=> state.user.current.username,
      user: state=> state.user.dataPegawai,
      current: state=> state.user.current
    }),
    baseURL: function () {
        let url = window.location.origin
        let x = "/#/"
        return url.concat(x)
    }
  },

  mounted(){
    // let kode_skpd = this.kode_skpd ? this.kode_skpd : local.getLocal("kolok")
    // this.getDataPegawaiSKPD(kode_skpd)
    // this.getDataReviewerSurveyReferensiASN()
  },

  methods:{
    limitCandidate() {
      if (this.modelListPegawaiSKPD.length > 3) {
        this.modelListPegawaiSKPD.pop()
        store.commit('snackbar/setSnack',{message: 'Mohon maaf, anda hanya bisa memilih 3 kandidat.', color:'warning'})
      }
      this.$nextTick(() => {
        this.searchString = '';
      });
    },

    updateTable(){
      this.pegawai=[]
    },

    getImg(nip, peg_foto){
      let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    },

    getNipNama(item) {
      return `${item.peg_nip} - ${item.peg_nama}`;
      // return `${item.nip} - ${item.nama}`;
    },

    addRow(){
      this.countPegawai = 0
      this.loadingBtn.add = true
      this.dataPegawaiBaru = []
      //reviewer check if its a PLT or not
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      let params_nip = []
      this.modelListPegawaiSKPD.forEach(element => {
        this.no += 1
        var my_object = {
          foto: element.foto,
          nama: element.peg_nama,
          nip: element.peg_nip,
          jabatan: element.jabatan,
          no:this.no,
          rating: 0,
          disabled: true
        }
        params_nip.push(element.peg_nip)
        this.dataPegawaiBaru.push(my_object)
      });

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_add_reviewer = base_url_api + 'v1/add-reviewer-asn-cerdas-berprestasi'
      var date = new Date()
      var bulan = date.getMonth()
      axios.post(url_add_reviewer, {
        nip: params_nip,
        nip_reviewer: current.username,
        bulan: (bulan+1),
      }).then(response => {
        let res = response.data
        if (res.success){
          if(this.dataPegawaiBaru.length >= 3){
            this.disabledTambahKandidat = true
          }else{
            this.disabledTambahKandidat = false
          }
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          this.dataPegawaiBaru = []
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.add = false
        this.anggota = this.dataPegawaiBaru
      }).catch(err => {
        var error = err.response.data.message
        this.loadingBtn.add = false
        this.dataPegawaiBaru = []
        this.anggota = this.dataPegawaiBaru
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    getDataPegawaiSKPD(kolok){
      // let kode_skpd = local.getLocal("kolok") ? local.getLocal("kolok") : this.$store.getters["user/data"].data_asn.kolok
      var kode_skpd = kolok ? kolok : local.getLocal("kolok")
      if(kode_skpd == null){
        kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
      }
      this.listPegawaiSKPD = []
      this.modelListPegawaiSKPD = []
      // SKPService.getPegawaiSKPD(kode_skpd).then(response=>{
      //   response.data.data.forEach(element => {
      //     element.foto = this.getImg(element.nip, element.peg_foto)
      //   });
      //   this.listPegawaiSKPD = response.data.data
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-pegawai-skpd-req-post'
      axios.post(url_get_data, {
        nip: current.username, kode_skpd: kode_skpd
      }).then(response => {
        response.data.data.forEach(element => {
          element.foto = this.getImg(element.nip, element.peg_foto)
        });
        // this.listPegawaiSKPD = response.data.data
        response.data.data.forEach(el => {
          if(el.peg_nip != current.username){
            this.listPegawaiSKPD.push(el)
          }
        });
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    openDialog(nip, idx){
      this.loadingEl = idx
      // let bulan = 8
      var date = new Date()
      var bulan = date.getMonth()
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/ulas-survey-referensi-asn-cerdas'
      axios.post(url_get_data, {
        nip: current.username,
        nip_bawahan: nip,
        bulan: bulan,
      }).then(response => {
        // let res = response.data
        console.log(response.data)
        this.dataPegawai = []
        this.dataPegawai = response.data
        this.dialogSurveyReferensiASNCerdas=true
      }).finally(()=>{
          this.loadingEl = -1
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
          this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
      // SurveyReferensiService.surveyReferensiUlasNIP(nip, bulan).then(response => {
      //   console.log(response.data)
      //   this.dataPegawai = []
      //   this.dataPegawai = response.data
      //   this.dialog=true
      // }).finally(() => {
      //   this.loadingEl = -1
      // })
    },

    getDataReviewerSurveyReferensiASN(){
      // this.form.check = false
      // var bulan = 8
      this.countPegawai = 0
      this.dataPegawaiBaru = []
      var date = new Date()
      var bulan = date.getMonth()
      //reviewer check if its a PLT or not
      this.loadingBtn.dataPegawai = true
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-data-reviewer-survey-referensi-asn-cerdas'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: (bulan+1),
      }).then(response => {
        let res = response.data
        if (res.success){
          res.data.forEach(element => {
            this.no += 1
            var my_object = {
              foto: element.foto,
              nama: element.peg_nama,
              nip: element.peg_nip,
              jabatan: element.jabatan,
              no:this.no,
              rating: element.rating == null ? 0 : parseInt(element.rating),
              disabled: element.submit_asn_teladan == false || element.submit_asn_teladan == null || element.remedial == true ? false : true,
              disabled_rating: element.rating == null ? false : true
            }
            if(element.nomor != null){
              this.dataPegawaiBaru.push(my_object)
            }

            if(element.check != null){
              this.disabledUlas = false
            }

            if(element.rating != null){
              this.countPegawai+=1
            }
          });
          this.anggota = this.dataPegawaiBaru
          if(this.anggota.length >= 3){
            this.disabledTambahKandidat = true
          }else{
            this.disabledTambahKandidat = false
          }
          store.commit('snackbar/setSnack',{message: this.anggota.length == 0 ? ' Data kandidat masih kosong, silahkan tambahkan dahulu.' : res.message, color: this.anggota.length == 0 ? 'warning' : 'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.dataPegawai = false
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingBtn.dataPegawai = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    storeRatingAndReviewer(peg, i){
      this.dialogSW = true
      this.namaPegawai = peg.nama
      this.formStrengthWeakness = []
      this.formStrengthWeakness.peg = peg
      this.formStrengthWeakness.index = i
    },

    storeSW(peg, i, bulan, current, url_store_jawaban){
     return axios.post(url_store_jawaban, {
        api:1,
        nip: peg.nip,
        nip_reviewer: current.username,
        val: [],
        id: [],
        bulan: (bulan+1),
        komentar_strength: this.formStrengthWeakness.strength,
        // komentar_weakness: this.formStrengthWeakness.weakness,
        komentar_weakness: null,
        is_sw:true
      }).then(response => {
      }).finally(()=>{
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    storeRating(peg, i, bulan, current, url_store_rating){
      return axios.post(url_store_rating, {
        nip: peg.nip,
        nip_reviewer: current.username,
        bulan: (bulan+1),
        rating: peg.rating
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          if(this.countPegawai < 3 && peg.rating != null){
            this.countPegawai+=1
          }
          this.anggota[i].disabled_rating = true
        } else {
          this.dataPegawaiBaru = []
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
      }).catch(err => {
        var error = err.response.data.message
        this.loadingBtn.add = false
        this.dataPegawaiBaru = []
        this.anggota = this.dataPegawaiBaru
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    simpanSW(){
      if (this.$refs.formSW.validate()){
        var peg = this.formStrengthWeakness.peg
        var i = this.formStrengthWeakness.index
        console.log('valid')
        var date = new Date()
        var bulan = date.getMonth()
        var current = this.$store.getters["user/current"]
        var base_url_api = _g.getBaseURLAPIERK(current.year)
        const url_store_jawaban = base_url_api + 'v1/asn-teladan-proses-submit'
        const url_store_rating = base_url_api + 'v1/store-rating-asn-cerdas-berprestasi'
        axios.all([
          this.storeSW(peg,i,bulan,current,url_store_jawaban),
          this.storeRating(peg,i,bulan,current,url_store_rating)
        ]).then(axios.spread((first_response, second_response) => {
          this.anggota[i].disabled = false
          this.loadingBtn.add = false
          this.anggota = this.dataPegawaiBaru
          this.dialogSW = false
        }))
      }
    },

    hapusKandidat(peg,i){
      // remove selected data in dropdownlist
      var checkRemove = this.modelListPegawaiSKPD
      if(checkRemove.length > 0){
        for (let i = 0; i < checkRemove.length; i++) {
					const el = checkRemove[i];
					if(el.peg_nip == peg.nip){
						checkRemove.splice(i, 1)
            if(this.countPegawai > 0){
              this.countPegawai-=1
            }
					}
				}
        this.modelListPegawaiSKPD = checkRemove
      }

      // remove data database
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url = base_url_api + 'v1/delete-reviewer-asn-teladan'
      var date = new Date()
      var bulan = date.getMonth()
      axios.post(url, {
        nip_reviewer: current.username,
        nip: peg.nip,
        bulan: (bulan+1),
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataReviewerSurveyReferensiASN()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    fixSimpanKandidat(){
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url = base_url_api + 'v1/store-fix-asn-teladan'
      var date = new Date()
      var bulan = date.getMonth()
      axios.post(url, {
        nip_reviewer: current.username,
        bulan: (bulan+1),
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataReviewerSurveyReferensiASN()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
